var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-container",
        [
          _c("tree-layout", {
            staticClass: "leftTree",
            attrs: {
              treeTitle: "组织架构",
              treeLoading: _vm.treeLoading,
              "tree-options": _vm.treeOptions,
            },
            on: {
              "tree-node-click": _vm.treeNodeClick,
              "include-down": _vm.includeDown,
            },
          }),
          _c(
            "el-container",
            [
              _c(
                "el-main",
                [
                  _c("head-layout", {
                    attrs: {
                      "head-btn-options": _vm.headBtnOptions,
                      "head-title": _vm.$t(
                        "cip.plat.sys.user.title.indexHeadTitle"
                      ),
                    },
                    on: {
                      "grid-enable": _vm.handleEnable,
                      "grid-disable": _vm.handleDisable,
                      "grid-import": _vm.handleImport,
                      "grid-export": _vm.handleExport,
                      "grid-unblock": _vm.handleLock,
                      "grid-roleConfig": _vm.handleGrant,
                      "grid-reset": _vm.handleReset,
                      "grid-platformConfig": _vm.handlePlatform,
                      "head-add": _vm.headAdd,
                      "head-people-add": _vm.headPeopleAdd,
                      "head-romve": _vm.headRomve,
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "headers" },
                    [
                      _c("grid-head-layout", {
                        ref: "searchFrom",
                        attrs: {
                          "head-title": _vm.$t(
                            "cip.plat.sys.user.title.indexHeadTitle"
                          ),
                          "search-columns": _vm.searchColumns,
                        },
                        on: {
                          "grid-head-search": _vm.searchChange,
                          "grid-head-empty": _vm.searchReset,
                        },
                        model: {
                          value: _vm.searchForm,
                          callback: function ($$v) {
                            _vm.searchForm = $$v
                          },
                          expression: "searchForm",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("grid-layout", {
                    ref: "gridLayout",
                    attrs: {
                      "head-title": _vm.$t(
                        "cip.plat.sys.user.title.indexHeadTitle"
                      ),
                      "data-total": _vm.page.total,
                      page: _vm.page,
                      tableOptions: _vm.tableOptions,
                      tableData: _vm.tableData,
                      tableLoading: _vm.tableLoading,
                      gridRowBtn: _vm.gridRowBtn,
                    },
                    on: {
                      "page-size-change": _vm.onLoad,
                      "page-current-change": _vm.onLoad,
                      "page-refresh-change": _vm.onLoad,
                      "gird-handle-select-click": _vm.selectionChange,
                      "grid-edit": _vm.rowEdit,
                      "grid-romve": _vm.rowDel,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.$t("cip.plat.sys.user.field.roleConfig"),
                "append-to-body": "",
                visible: _vm.roleBox,
                width: "345px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.roleBox = $event
                },
              },
            },
            [
              _c("el-tree", {
                ref: "treeRole",
                attrs: {
                  data: _vm.roleGrantList,
                  "show-checkbox": "",
                  "check-strictly": "",
                  "default-expand-all": "",
                  "node-key": "id",
                  "default-checked-keys": _vm.roleTreeObj,
                  props: _vm.props,
                },
              }),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.roleBox = false
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("cip.cmn.btn.celBtn")))]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitRole },
                    },
                    [_vm._v(_vm._s(_vm.$t("cip.cmn.btn.defBtn")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.roleConfigDialog
            ? _c(
                "el-dialog",
                {
                  staticClass: "roleDialog",
                  attrs: {
                    title: _vm.$t("cip.plat.sys.user.field.roleConfig"),
                    "append-to-body": "",
                    top: "5vh",
                    visible: _vm.roleConfigDialog,
                    width: "80%",
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.roleConfigDialog = $event
                    },
                  },
                },
                [
                  _c("department", {
                    ref: "department",
                    attrs: { userList: _vm.selectionList },
                  }),
                  _c(
                    "span",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              _vm.roleConfigDialog = false
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("cip.cmn.btn.celBtn")))]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.nextStep },
                        },
                        [
                          _vm._v(
                            _vm._s(/*$t("cip.cmn.btn.nextStep")*/ "下一步")
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.departmentUserDialog
            ? _c(
                "el-dialog",
                {
                  staticClass: "roleDialog",
                  attrs: {
                    title: _vm.$t("cip.plat.sys.user.field.roleConfig"),
                    "append-to-body": "",
                    visible: _vm.departmentUserDialog,
                    width: "80%",
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.departmentUserDialog = $event
                    },
                  },
                },
                [
                  _c("departmentUser", { ref: "departmentUsers" }),
                  _c(
                    "span",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              _vm.departmentUserDialog = false
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("cip.cmn.btn.celBtn")))]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.submitRole },
                        },
                        [_vm._v(_vm._s(_vm.$t("cip.cmn.btn.defBtn")))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.$t("cip.plat.sys.user.field.userImport"),
                "append-to-body": "",
                visible: _vm.excelBox,
                width: "555px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.excelBox = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "import" },
                [
                  _c(
                    "avue-form",
                    {
                      attrs: {
                        option: _vm.excelOption,
                        "upload-after": _vm.uploadAfter,
                      },
                      model: {
                        value: _vm.excelForm,
                        callback: function ($$v) {
                          _vm.excelForm = $$v
                        },
                        expression: "excelForm",
                      },
                    },
                    [
                      _c(
                        "template",
                        { slot: "excelTemplate" },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.handleTemplate },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t("cip.plat.sys.user.field.download")
                                  )
                              ),
                              _c("i", {
                                staticClass: "el-icon-download el-icon--right",
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.$t("cip.plat.sys.user.field.platformConfig"),
                "append-to-body": "",
                visible: _vm.platformBox,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.platformBox = $event
                },
              },
            },
            [
              _c("avue-crud", {
                ref: "platformCrud",
                attrs: {
                  option: _vm.platformOption,
                  "table-loading": _vm.platformLoading,
                  data: _vm.platformData,
                  "before-open": _vm.platformBeforeOpen,
                  page: _vm.platformPage,
                  permission: _vm.platformPermissionList,
                },
                on: {
                  "update:page": function ($event) {
                    _vm.platformPage = $event
                  },
                  "row-update": _vm.platformRowUpdate,
                  "search-change": _vm.platformSearchChange,
                  "search-reset": _vm.platformSearchReset,
                  "selection-change": _vm.platformSelectionChange,
                  "current-change": _vm.platformCurrentChange,
                  "size-change": _vm.platformSizeChange,
                  "refresh-change": _vm.platformRefreshChange,
                  "on-load": _vm.platformOnLoad,
                },
                scopedSlots: _vm._u([
                  {
                    key: "tenantName",
                    fn: function ({ row }) {
                      return [_c("el-tag", [_vm._v(_vm._s(row.tenantName))])]
                    },
                  },
                  {
                    key: "userTypeName",
                    fn: function ({ row }) {
                      return [_c("el-tag", [_vm._v(_vm._s(row.userTypeName))])]
                    },
                  },
                ]),
                model: {
                  value: _vm.platformForm,
                  callback: function ($$v) {
                    _vm.platformForm = $$v
                  },
                  expression: "platformForm",
                },
              }),
            ],
            1
          ),
          _c("userAddPeopleDialog", {
            ref: "userAddPeopleDialog",
            on: { changeUser: _vm.changeUser },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }