<template>
    <el-dialog
      :title="title"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :visible.sync="showDialog"
      width="80%"
      top="5vh"
      class="qmDialog"
      @close="closeDialog"
    >
      <userList v-if="showDialog" ref="userList" :isIndex="false" @selectUser="rowSelect"></userList>
    </el-dialog>
  </template>
      
  <script>
  import userList from "@/views/system/sync/user/index.vue";
  export default {
    components: {
        userList,
    },
    data() {
      return {
        showDialog: false,
        title: "",
      };
    },
    methods: {
      init() {
          this.showDialog = true;
          this.title = "添加人员";
      },
      // 选择公司
      rowSelect(row) {
          this.$emit('changeUser',row)
          this.closeDialog()
      },
      closeDialog() {
        this.showDialog = false;
      },
    },
  };
  </script>
      