<template>
  <div>
    <el-container>
      <tree-layout
        treeTitle="组织架构"
        :treeLoading="treeLoading"
        :tree-options="treeOptions"
        @tree-node-click="treeNodeClick"
        @include-down="includeDown"
        class="leftTree"
      ></tree-layout>
      <el-container>
        <el-main>
          <head-layout
            :head-btn-options="headBtnOptions"
            @grid-enable="handleEnable"
            @grid-disable="handleDisable"
            @grid-import="handleImport"
            @grid-export="handleExport"
            @grid-unblock="handleLock"
            @grid-roleConfig="handleGrant"
            @grid-reset="handleReset"
            @grid-platformConfig="handlePlatform"
            :head-title="$t('cip.plat.sys.user.title.indexHeadTitle')"
            @head-add="headAdd"
            @head-people-add="headPeopleAdd"
            @head-romve="headRomve"
          >
          </head-layout>
          <div class="headers">
            <grid-head-layout
              ref="searchFrom"
              :head-title="$t('cip.plat.sys.user.title.indexHeadTitle')"
              :search-columns="searchColumns"
              v-model="searchForm"
              @grid-head-search="searchChange"
              @grid-head-empty="searchReset"
            >
            </grid-head-layout>
          </div>

          <grid-layout
            ref="gridLayout"
            :head-title="$t('cip.plat.sys.user.title.indexHeadTitle')"
            :data-total="page.total"
            @page-size-change="onLoad"
            @page-current-change="onLoad"
            @page-refresh-change="onLoad"
            :page="page"
            @gird-handle-select-click="selectionChange"
            :tableOptions="tableOptions"
            :tableData="tableData"
            :tableLoading="tableLoading"
            :gridRowBtn="gridRowBtn"
            @grid-edit="rowEdit"
            @grid-romve="rowDel"
          >
          </grid-layout>
        </el-main>
      </el-container>
      <el-dialog
        :title="$t('cip.plat.sys.user.field.roleConfig')"
        append-to-body
        :visible.sync="roleBox"
        width="345px"
      >
        <el-tree
          :data="roleGrantList"
          show-checkbox
          check-strictly
          default-expand-all
          node-key="id"
          ref="treeRole"
          :default-checked-keys="roleTreeObj"
          :props="props"
        >
        </el-tree>

        <span slot="footer" class="dialog-footer">
          <el-button @click="roleBox = false">{{
            $t("cip.cmn.btn.celBtn")
          }}</el-button>
          <el-button type="primary" @click="submitRole">{{
            $t("cip.cmn.btn.defBtn")
          }}</el-button>
        </span>
      </el-dialog>
      <el-dialog
        :title="$t('cip.plat.sys.user.field.roleConfig')"
        append-to-body
        top="5vh"
        :visible.sync="roleConfigDialog"
        v-if="roleConfigDialog"
        width="80%"
        class="roleDialog"
      >
        <department ref="department" :userList="selectionList"></department>
        <span slot="footer" class="dialog-footer">
          <el-button @click="roleConfigDialog = false">{{
            $t("cip.cmn.btn.celBtn")
          }}</el-button>
          <el-button type="primary" @click="nextStep">{{
            /*$t("cip.cmn.btn.nextStep")*/
            "下一步"
          }}</el-button>
        </span>
      </el-dialog>
      <el-dialog
        :title="$t('cip.plat.sys.user.field.roleConfig')"
        append-to-body
        :visible.sync="departmentUserDialog"
        v-if="departmentUserDialog"
        width="80%"
        class="roleDialog"
      >
        <departmentUser ref="departmentUsers"></departmentUser>
        <span slot="footer" class="dialog-footer">
          <el-button @click="departmentUserDialog = false">{{
            $t("cip.cmn.btn.celBtn")
          }}</el-button>
          <el-button type="primary" @click="submitRole">{{
            $t("cip.cmn.btn.defBtn")
          }}</el-button>
        </span>
      </el-dialog>
      <el-dialog
        :title="$t('cip.plat.sys.user.field.userImport')"
        append-to-body
        :visible.sync="excelBox"
        width="555px"
      >
        <div class="import">
          <avue-form
            :option="excelOption"
            v-model="excelForm"
            :upload-after="uploadAfter"
          >
            <template slot="excelTemplate">
              <el-button type="primary" @click="handleTemplate">
                {{ $t("cip.plat.sys.user.field.download")
                }}<i class="el-icon-download el-icon--right"></i>
              </el-button>
            </template>
          </avue-form>
        </div>
      </el-dialog>
      <el-dialog
        :title="$t('cip.plat.sys.user.field.platformConfig')"
        append-to-body
        :visible.sync="platformBox"
      >
        <avue-crud
          :option="platformOption"
          :table-loading="platformLoading"
          :data="platformData"
          ref="platformCrud"
          v-model="platformForm"
          :before-open="platformBeforeOpen"
          :page.sync="platformPage"
          :permission="platformPermissionList"
          @row-update="platformRowUpdate"
          @search-change="platformSearchChange"
          @search-reset="platformSearchReset"
          @selection-change="platformSelectionChange"
          @current-change="platformCurrentChange"
          @size-change="platformSizeChange"
          @refresh-change="platformRefreshChange"
          @on-load="platformOnLoad"
        >
          <template slot-scope="{ row }" slot="tenantName">
            <el-tag>{{ row.tenantName }}</el-tag>
          </template>
          <template slot-scope="{ row }" slot="userTypeName">
            <el-tag>{{ row.userTypeName }}</el-tag>
          </template>
        </avue-crud>
      </el-dialog>
      <!-- 添加人员弹框 -->
      <userAddPeopleDialog
        ref="userAddPeopleDialog"
        @changeUser="changeUser"
      ></userAddPeopleDialog>
    </el-container>
  </div>
</template>

<script>
import userAddPeopleDialog from "./userAddPeopleDialog.vue";
import { PAGE_CONSTANT } from "@/util/pageConstantEnum";
import { getSysConfig, theUserModifiesTheRole } from "@/api/system/user";
import {
  getList,
  getUser,
  getUserPlatform,
  remove,
  update,
  updatePlatform,
  add,
  grant,
  grantMultiDept,
  resetPassword,
  unlock,
  enable,
  disable,
} from "@/api/system/user";
import department from "@/views/system/department";
import departmentUser from "@/views/system/departmentUser";
import {
  remove as delDept,
  getList as deptList,
  getDept,
} from "@/api/system/dept";
import { exportBlob } from "@/api/common";
import { getDeptTree, getDeptLazyTree } from "@/api/system/dept";
import { getRoleTree } from "@/api/system/role";
import { getPostList } from "@/api/system/post";
import { mapGetters } from "vuex";
import website from "@/config/website";
import { getToken } from "@/util/auth";
import { downloadXls } from "@/util/util";
import { dateNow } from "@/util/date";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import TreeLayout from "@/views/components/layout/tree-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import CommonTree from "@/views/components/com_tree/index";

export default {
  name: "user",
  components: {
    TreeLayout,
    HeadLayout,
    GridLayout,
    CommonTree,
    // roleConfigDialog,
    department,
    departmentUser,
    userAddPeopleDialog,
  },

  data() {
    const validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t("cip.plat.sys.user.field.inputPassword")));
      } else {
        callback();
      }
    };
    const validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t("cip.plat.sys.user.field.checkPassword")));
      } else if (value !== this.$refs.formLayout.$refs.form.form.password) {
        callback(new Error(this.$t("cip.plat.sys.user.field.uncheckPassword")));
      } else {
        callback();
      }
    };
    return {
      deptDicData: [],
      treeList: [],
      treeLoading: true,
      departmentUserDialog: false,
      roleConfigDialog: false,
      deptList: [],
      searchTitle: "title",
      defaultProps: {
        label: "title",
        value: "key",
      },
      tenantStatus: "",
      searchForm: {},
      gridRowBtn: [
        {
          label: this.$t("cip.cmn.btn.editBtn"),
          emit: "grid-edit",
          remark: "edit",
          type: "text",
          icon: "",
        },
        {
          label: this.$t("cip.cmn.btn.delBtn"),
          emit: "grid-romve",
          remark: "remove",
          type: "text",
          icon: "",
        },
      ],
      headBtnOptions: [
        {
          label: this.$t("cip.cmn.btn.addPeopleBtn"),
          emit: "head-people-add",
          type: "button",
          remark: "",
          icon: "",
        },
        {
          label: this.$t("cip.cmn.btn.addBtn"),
          emit: "head-add",
          type: "button",
          remark: "",
          icon: "",
        },
        {
          label: this.$t("cip.cmn.btn.delBtn"),
          emit: "head-romve",
          type: "button",
          remark: "user_delete",
          icon: "",
        },

        {
          label: this.$t("cip.plat.sys.user.btn.restPassword"),
          emit: "grid-reset",
          type: "button",
          remark: "user_reset",
          icon: "",
        },
        {
          label: this.$t("cip.plat.sys.user.btn.roleConfig"),
          emit: "grid-roleConfig",
          type: "button",
          remark: "user_role",
          icon: "",
        },
        // {
        //   label: this.$t('sinoma.btn.platform'),
        //   emit: 'grid-platformConfig',
        //   type: 'button',
        //   remark: 'admin',
        //   icon: '',
        // },
        {
          label: this.$t("cip.plat.sys.user.btn.accountConfig"),
          emit: "grid-unblock",
          remark: "admin",
          type: "button",
          icon: "",
        },
        {
          label: this.$t("cip.plat.sys.user.btn.enable"),
          emit: "grid-enable",
          remark: "admin",
          type: "button",
          icon: "",
        },
        {
          label: this.$t("cip.plat.sys.user.btn.disable"),
          emit: "grid-disable",
          remark: "admin",
          type: "button",
          icon: "",
        },
        {
          label: this.$t("cip.plat.sys.user.btn.export"),
          emit: "grid-export",
          remark: "admin",
          type: "button",
          icon: "",
        },
        // {
        //   label: this.$t("cip.plat.sys.user.btn.import"),
        //   emit: "grid-import",
        //   remark: "admin",
        //   type: "button",
        //   icon: "",
        // },
      ],
      dataTotal: null,
      tableData: [],
      tableLoading: true,
      form: {},
      search: {},
      roleBox: false,
      excelBox: false,
      platformBox: false,
      initFlag: true,
      selectionList: [],
      query: {},
      loading: true,
      platformLoading: false,
      page: {
        pageSize: PAGE_CONSTANT.TEN,
        currentPage: 1,
        total: 0,
      },
      platformPage: {
        pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE,
        currentPage: 1,
        total: 0,
      },
      init: {
        roleTree: [],
        deptTree: [],
      },
      props: {
        label: "title",
        value: "key",
      },
      roleGrantList: [],
      roleTreeObj: [],
      treeDeptId: "",
      treeData: [],
      tableOptions: {
        customRowAddBtn: false,
        column: [
          {
            label: this.$t("cip.plat.sys.user.field.account"),
            prop: "account",
            cell: true,
            align: "left",
            width: 150,
            overHidden: true,
          },
          {
            label: this.$t("cip.plat.sys.user.field.tenantName"),
            prop: "tenantName",
            cell: true,
            hide: "",
            width: 150,
            align: "center",
            overHidden: true,
          },
          {
            label: this.$t("cip.plat.sys.user.field.realName"),
            prop: "realName",
            cell: true,
            width: 150,
            align: "center",
            overHidden: true,
          },
          /*{
            label: this.$t("cip.plat.sys.user.field.roleName"),
            prop: "roleName",
            cell: true,
            align: 'left',
            overHidden: true,
          },*/
          {
            label: this.$t("cip.plat.sys.user.field.deptName"),
            prop: "deptName",
            cell: true,
            align: "left",
            overHidden: true,
          },
          {
            label: this.$t("cip.plat.sys.user.field.isEnabled"),
            prop: "isEnabled",
            cell: true,
            // dicData: [
            //   {
            //     label: this.$t("cip.plat.sys.user.field.enable"),
            //     value: 0
            //   },
            //   {
            //     label: this.$t("cip.plat.sys.user.field.disable"),
            //     value: 1
            //   }
            // ],
            dataType: "number",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            width: 150,
            align: "center",
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=ISENBLE",
          },
          {
            label: this.$t("cip.plat.sys.user.field.userType"),
            type: "select",
            dicUrl: "/api/sinoma-system/dict/dictionary?code=user_type",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            align: "center",
            dataType: "number",
            prop: "userType",
            hide: true,
            width: 150,
            overHidden: true,
          },
        ],
        group: [
          {
            label: this.$t("cip.plat.sys.user.field.baseInfo"),
            prop: "baseInfo",
            icon: "el-icon-user-solid",
            column: [
              {
                label: this.$t("cip.plat.sys.user.field.tenantName"),
                prop: "tenantId",
                type: "tree",
                dicUrl: "/api/sinoma-system/tenant/select",
                props: {
                  label: "tenantName",
                  value: "tenantId",
                },
                hide: !website.tenantMode,
                addDisplay: website.tenantMode,
                editDisplay: website.tenantMode,
                viewDisplay: website.tenantMode,
                rules: [
                  {
                    required: true,
                    message:
                      this.$t("cip.cmn.rule.selectWarning") +
                      this.$t("cip.plat.sys.user.field.tenantName"),
                    trigger: "click",
                  },
                ],
                span: 24,
              },
              {
                label: this.$t("cip.plat.sys.user.field.account"),
                prop: "account",
                rules: [
                  {
                    required: true,
                    message:
                      this.$t("cip.cmn.rule.inputWarning") +
                      this.$t("cip.plat.sys.user.field.account"),
                    trigger: "blur",
                  },
                ],
              },
              {
                label: this.$t("cip.plat.sys.user.field.userType"),
                type: "select",
                dicUrl: "/api/sinoma-system/dict/dictionary?code=user_type",
                props: {
                  label: "dictValue",
                  value: "dictKey",
                },
                dataType: "number",
                slot: true,
                prop: "userType",
                rules: [
                  {
                    required: true,
                    message:
                      this.$t("cip.cmn.rule.selectWarning") +
                      this.$t("cip.plat.sys.user.field.userType"),
                    trigger: "blur",
                  },
                ],
              },
              {
                label: this.$t("cip.plat.sys.user.field.password"),
                prop: "password",
                hide: true,
                editDisplay: false,
                viewDisplay: false,
                rules: [
                  { required: true, validator: validatePass, trigger: "blur" },
                ],
              },
              {
                label: this.$t("cip.plat.sys.user.field.password2"),
                prop: "password2",
                hide: true,
                editDisplay: false,
                viewDisplay: false,
                rules: [
                  { required: true, validator: validatePass2, trigger: "blur" },
                ],
              },
            ],
          },
          {
            label: this.$t("cip.plat.sys.user.field.detailInfo"),
            prop: "detailInfo",
            icon: "el-icon-s-order",
            column: [
              {
                label: this.$t("cip.plat.sys.user.field.name"),
                prop: "name",
                hide: true,
                rules: [
                  {
                    required: true,
                    message:
                      this.$t("cip.cmn.rule.inputWarning") +
                      this.$t("cip.plat.sys.user.field.name"),
                    trigger: "blur",
                  },
                ],
              },
              {
                label: this.$t("cip.plat.sys.user.field.realName"),
                prop: "realName",
                rules: [
                  {
                    required: true,
                    message:
                      this.$t("cip.cmn.rule.inputWarning") +
                      this.$t("cip.plat.sys.user.field.realName"),
                    trigger: "blur",
                  },
                  {
                    min: 2,
                    max: 5,
                    message: this.$t("cip.plat.sys.user.field.nameLength"),
                  },
                ],
              },
              {
                label: this.$t("cip.plat.sys.user.field.phone"),
                prop: "phone",
                overHidden: true,
              },
              {
                label: this.$t("cip.plat.sys.user.field.email"),
                prop: "email",
                hide: true,
                overHidden: true,
              },
              {
                label: this.$t("cip.plat.sys.user.field.sex"),
                prop: "sex",
                type: "select",
                // dicData: [
                //   {
                //     label: this.$t("cip.plat.sys.user.field.man"),
                //     value: 1
                //   },
                //   {
                //     label: this.$t("cip.plat.sys.user.field.women"),
                //     value: 2
                //   },
                //   {
                //     label: this.$t("cip.plat.sys.user.field.unknow"),
                //     value: 3
                //   }
                // ],
                dataType: "number",
                props: {
                  label: "dictValue",
                  value: "dictKey",
                },
                dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=project_sex_type",
                hide: true,
              },
              {
                label: this.$t("cip.plat.sys.user.field.birthday"),
                type: "date",
                prop: "birthday",
                format: "yyyy-MM-dd hh:mm:ss",
                valueFormat: "yyyy-MM-dd hh:mm:ss",
                hide: true,
              },
              {
                label: this.$t("cip.plat.sys.user.field.statusName"),
                prop: "statusName",
                hide: true,
                display: false,
              },
            ],
          },
          {
            label: this.$t("cip.plat.sys.user.field.dutyInfo"),
            prop: "dutyInfo",
            icon: "el-icon-s-custom",
            column: [
              {
                label: this.$t("cip.plat.sys.user.field.code"),
                prop: "code",
              },
              {
                label: this.$t("cip.plat.sys.user.field.roleName"),
                prop: "roleId",
                multiple: true,
                type: "tree",
                dicData: [],
                props: {
                  label: "title",
                },
                checkStrictly: true,
                slot: true,
                rules: [
                  {
                    required: true,
                    message:
                      this.$t("cip.cmn.rule.selectWarning") +
                      this.$t("cip.plat.sys.user.field.roleName"),
                    trigger: "click",
                  },
                ],
              },
              {
                label: this.$t("cip.plat.sys.user.field.roleName"),
                prop: "deptId",
                type: "tree",
                multiple: true,
                dicData: [],
                props: {
                  label: "title",
                },
                checkStrictly: true,
                slot: true,
                rules: [
                  {
                    required: true,
                    message:
                      this.$t("cip.cmn.rule.selectWarning") +
                      this.$t("cip.plat.sys.user.field.roleName"),
                    trigger: "click",
                  },
                ],
              },
              {
                label: this.$t("cip.plat.sys.user.field.postId"),
                prop: "postId",
                type: "tree",
                multiple: true,
                dicData: [],
                props: {
                  label: "postName",
                  value: "id",
                },
                rules: [
                  {
                    required: true,
                    message:
                      this.$t("cip.cmn.rule.selectWarning") +
                      this.$t("cip.plat.sys.user.field.postId"),
                    trigger: "click",
                  },
                ],
              },
            ],
          },
        ],
      },
      data: [],
      platformQuery: {},
      platformSelectionList: [],
      platformData: [],
      platformForm: {},
      platformOption: {
        tip: false,
        searchShow: true,
        searchMenuSpan: 6,
        border: true,
        index: true,
        selection: true,
        viewBtn: false,
        dialogClickModal: false,
        menuWidth: 120,
        editBtnText: this.$t("cip.plat.sys.user.field.config"),
        column: [
          {
            label: this.$t("cip.plat.sys.user.field.account"),
            prop: "account",
            search: true,
            display: false,
          },
          {
            label: this.$t("cip.plat.sys.user.field.tenantName"),
            prop: "tenantName",
            slot: true,
            display: false,
          },
          {
            label: this.$t("cip.plat.sys.user.field.realName"),
            prop: "realName",
            search: true,
            display: false,
          },
          {
            label: this.$t("cip.plat.sys.user.field.userType"),
            prop: "userTypeName",
            slot: true,
            display: false,
          },
          {
            label: this.$t("cip.plat.sys.user.field.userType"),
            type: "select",
            dicUrl: "/api/sinoma-system/dict/dictionary?code=user_type",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            dataType: "number",
            prop: "userType",
            rules: [
              {
                required: true,
                message:
                  this.$t("cip.cmn.rule.selectWarning") +
                  this.$t("cip.plat.sys.user.field.userType"),
                trigger: "blur",
              },
            ],
          },
          {
            label: this.$t("cip.plat.sys.user.field.userExt"),
            prop: "userExt",
            type: "textarea",
            minRows: 8,
            span: 24,
            overHidden: true,
            row: true,
            hide: true,
          },
        ],
      },
      excelForm: {},
      excelOption: {
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            label: this.$t("cip.plat.sys.user.field.excelFile"),
            prop: "excelFile",
            type: "upload",
            drag: true,
            loadText: this.$t("cip.plat.sys.user.field.waiting"),
            span: 24,
            propsHttp: {
              res: "data",
            },
            tip: this.$t("cip.plat.sys.user.field.format"),
            action: "/api/sinoma-user/import-user",
          },
          {
            label: this.$t("cip.plat.sys.user.field.isCovered"),
            prop: "isCovered",
            type: "select",
            align: "center",
            width: 80,
            // dicData: [
            //   {
            //     label: this.$t("cip.plat.sys.user.field.no"),
            //     value: 0
            //   },
            //   {
            //     label: this.$t("cip.plat.sys.user.field.yes"),
            //     value: 1
            //   }
            // ],
            dataType: "number",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=yesNo",
            value: 0,
            slot: true,
            rules: [
              {
                required: true,
                message:
                  this.$t("cip.cmn.rule.selectWarning") +
                  this.$t("cip.plat.sys.user.field.yes") +
                  this.$t("cip.plat.sys.user.field.no") +
                  this.$t("cip.plat.sys.user.field.isCovered"),
                trigger: "blur",
              },
            ],
          },
          {
            label: this.$t("cip.plat.sys.user.field.excelTemplate"),
            prop: "excelTemplate",
            formslot: true,
            span: 24,
          },
        ],
      },
      searchColumns:[
        {
          type: "input",
          prop: "account",
          span: 4,
          placeholder: "请输入登录账号",
        },
        {
          prop: "realName",
          type: "input",
          span: 4,
          placeholder: "请输入用户姓名",
        },
        {
          label: "用户类型",
          prop: "personnelType",
          type: "select",
          multiple:true,
          dicData: [],
          props: {
            label: "dictValue",
            value: "dictKey",
          },
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=personnel_type",
          span: 4,
          placeholder: "请选择用户类型",
        },
        {
          prop: "roleId",
          span: 4,
          multiple: true,
          type: "tree",
          dicUrl: "api/sinoma-system/role/treeByUserType?tenantId=000000",
          dicData: [],
          props: {
            label: "title",
          },
          checkStrictly: true,
          placeholder: "请选择所属角色",
        },
        {
          prop: "postId",
          span: 4,
          type: "tree",
          multiple: true,
          dicUrl: "/api/sinoma-system/post/select?tenantId=000000",
          dicData: [],
          props: {
            label: "postName",
            value: "id",
          },
          placeholder: "请选择所属岗位",
        },
      ]
    };
  },
  watch: {
    "form.tenantId"() {
      if (this.form.tenantId !== "" && this.initFlag) {
        this.initData(this.form.tenantId);
      }
    },
    "excelForm.isCovered"() {
      if (this.excelForm.isCovered !== "") {
        const column = this.findObject(this.excelOption.column, "excelFile");
        column.action = `/api/sinoma-user/import-user?isCovered=${this.excelForm.isCovered}`;
      }
    },
  },
  computed: {
    ...mapGetters(["userInfo", "permission"]),
    treeOptions() {
      let that = this;
      return {
        defaultExpandAll: false,
        nodeKey: "id",
        lazy: true,
        treeLoad: function (node, resolve) {
          that.treeLoading = true;
          const parentId = node.level === 0 ? that.userInfo.parent_dept_id : node.data.id;
          getDeptLazyTree(parentId).then((res) => {
              that.treeLoading = false;
              resolve(
                res.data.data.map((item) => {
                  return {
                    ...item,
                    leaf: !item.hasChildren,
                  };
                })
              );
          });
        },
        menu: false,
        addBtn: false,
        // size: 'mini',
        props: {
          labelText: this.$t("cip.plat.sys.user.field.title"),
          label: "title",
          value: "value",
          children: "children",
        },
      };
    },
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.user_add, false),
        viewBtn: this.vaildData(this.permission.user_view, false),
        delBtn: this.vaildData(this.permission.user_delete, false),
        editBtn: this.vaildData(this.permission.user_edit, false),
      };
    },
    platformPermissionList() {
      return {
        addBtn: false,
        viewBtn: false,
        delBtn: false,
        editBtn: this.vaildData(this.permission.user_edit, false),
      };
    },
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
  },
  created() {
    this.getSysConfig();
    // this.permission.user_role=false
    this.headBtnOptions.forEach((item, index) => {
      if (!this.userInfo.role_name.includes("administrator")) {
        if (item.remark == "admin") {
          this.headBtnOptions.splice(index, 1);
        }
      }

      if (!this.permission.user_reset) {
        if (item.remark == "user_reset") {
          this.headBtnOptions.splice(index, 1);
        }
      }
      if (!this.permission.user_role) {
        if (item.remark == "user_role") {
          this.headBtnOptions.splice(index, 1);
        }
      }
      if (!this.permission.user_delete) {
        if (item.remark == "user_delete") {
          this.headBtnOptions.splice(index, 1);
        }
      }
      if (!this.permission.user_add) {
        if (item.remark == "user_add") {
          this.headBtnOptions.splice(index, 1);
        }
      }
    });

    this.gridRowBtn.forEach((item, index) => {
      if (!this.permission.user_edit) {
        if (item.remark == "edit") {
          this.gridRowBtn.splice(index, 1);
        }
      }
      if (!this.permission.user_delete) {
        if (item.remark == "remove") {
          this.gridRowBtn.splice(index, 1);
        }
      }
    });
  },
  mounted() {
    // 非租户模式默认加载管理组数据
    // if (!website.tenantMode) {
    //   this.initData(website.tenantId);
    // }
    this.initData(website.tenantId);
    this.deptData();
    this.getTreeData();
    this.onLoad(
      {
        pageSize: 20,
        currentPage: 1,
      },
      {}
    );
  },
  methods: {
    getSysConfig() {
      getSysConfig().then((res) => {
        let data = res.data.data;
        this.tenantStatus = data.is_tenant;
        if (this.tenantStatus != "true") {
          this.$refs.gridLayout.tableOptions.column[1].hide = true;
        }
      });
    },
    deptData() {
      deptList(0, Object.assign({}, {})).then((res) => {
        this.deptList = res.data.data;
      });
    },
    // 机构新增
    getTreeAdd() {
      this.$router.push({
        path: "/dept/deptEdit",
        query: {
          type: "add",
          tenantStatus: this.tenantStatus,
        },
      });
    },
    // 机构删除
    getTreeDelete(data) {
      console.log(data, "sdfs");
      this.$confirm(this.$t("cip.cmn.msg.warning.delWarning"), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      })
        .then(() => {
          return delDept(data.id);
        })
        .then(() => {
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess"),
          });
          this.getTreeData();
        });
    },
    // 机构编辑
    getTreeEdit(data) {
      for (let i = 0; i < this.deptList.length; i++) {
        if (data.id == this.deptList[i].id) {
          console.log(this.deptList[i], "t");
          this.getEditPush(this.deptList[i]);
        }
      }
    },
    getEditPush(cdata) {
      let data = encodeURIComponent(JSON.stringify(cdata));
      this.$router.push({
        path: "/dept/deptEdit",
        query: {
          type: "edit",
          data: data,
        },
      });
    },
    getTreeData() {
      getDeptTree().then((res) => {
        this.treeData = res.data.data;
      });
    },
    rowEdit(row) {
      this.$router.push({
        path: "/user/edit2",
        query: {
          type: "edit",
          id: row.id,
        },
      });
    },
    gridHeadSearch() {
      console.log(JSON.stringify(this.$refs.gridLayOut.searchForm));
      // this.$message.success(
      //   '您点击了GRID-HEAD区域的搜索' + JSON.stringify(this.$refs.gridLayOut.searchForm)
      // )
    },
    gridHeadEmpty() {
      console.log(JSON.stringify(this.$refs.gridLayOut.searchForm));
      // this.$message.success(
      //   '您点击了GRID-HEAD区域的清除' + JSON.stringify(this.$refs.gridLayOut.searchForm)
      // )
    },
    gridHeadAdd() {
      this.isDataForm = true;
      this.isShow = true;
      // this.$message.success(
      //   '您点击了GRID-HEAD区域的新增'
      // )
    },
    gridHeadAddTable() {
      this.isGridData = true;
      this.isShow = true;
      // this.$message.success(
      //   '您点击了GRID-HEAD区域的新增'
      // )
    },
    gridSearchClick(searchForm) {
      // this.$message.success(
      //   '搜索' + JSON.stringify(searchForm)
      // )
    },
    gridClearClick(searchForm) {
      // this.$message.success(
      //   '清除' + JSON.stringify(searchForm)
      // )
    },
    gridRowSave(rowForm) {
      // this.$message.success(
      //   '保存的数据：' + JSON.stringify(rowForm)
      // )
    },
    gridRowRemove(rowForm) {
      // this.$message.success(
      //   '删除的数据：' + JSON.stringify(rowForm)
      // )
    },
    // 添加人员
    headPeopleAdd() {
      this.$refs.userAddPeopleDialog.init();
    },
    // 添加人员选择用户返回值
    changeUser(row) {
      this.$router.push({
        path: "/user/add2",
        query: {
          type: "add",
          tenantStatus: this.tenantStatus,
          row: encodeURIComponent(JSON.stringify(row)),
        },
        // query: {
        //   data: encodeURIComponent(JSON.stringify(row)),
        //   type: 'add'
        // }
      });
    },
    headAdd() {
      this.$router.push({
        path: "/user/add2",
        query: {
          type: "add",
          tenantStatus: this.tenantStatus,
        },
        // query: {
        //   data: encodeURIComponent(JSON.stringify(row)),
        //   type: 'add'
        // }
      });
    },
    headRomve() {
      console.log(111111);
      // const selectRows = this.$refs.gridLayout.$refs.crud.tableSelect;
      this.selectionList = this.$refs.gridLayout.$refs.grid.tableSelect;
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t("cip.cmn.msg.warning.selectWarning"));
        return;
      }
      this.$confirm(this.$t("cip.cmn.msg.warning.delWarning"), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      })
        .then(() => {
          return remove(this.ids);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess"),
          });
        });
    },
    headRemoveClick() {},
    includeDown(e) {
      this.page.currentPage = 1;
      this.query.isInclude = e;
      this.onLoad(this.page);
    },
    getNodeClick(data) {
      this.treeDeptId = data.id;
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },
    treeNodeClick(node) {
      this.treeDeptId = node.id;
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },
    nodeClick(data) {
      this.treeDeptId = data.id;
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },
    initData(tenantId) {
      getDeptTree(tenantId).then((res) => {
        this.deptDicData = res.data.data;
      });
    },
    findChildData(tree, childId) {
      for (let i = 0; i < tree.length; i++) {
        const node = tree[i];
        if (node.id === childId) {
          // 如果当前节点就是目标子节点，直接返回其数据
          return node;
        } else if (node.children) {
          // 否则递归查找当前节点的子节点
          const childData = this.findChildData(node.children, childId);
          if (childData) {
            // 如果找到了目标子节点的数据，返回该数据
            return childData;
          }
        }
      }
      // 没有找到目标子节点的数据，返回 null
      return null;
    },
    nextStep() {
      let deptList = [];
      this.treeList = this.$refs.department.submitDepartment();
      console.log("进行输出-------------", this.treeList);
      var item = this.deptDicData;
      this.treeList.forEach((e) => {
        //查询本级部门
        let findChildData = this.findChildData(item, e.deptId);
        if (findChildData != null) {
          let findParentNode1 = {};
          findParentNode1.departmentId = findChildData.id;
          findParentNode1.department = findChildData.title;
          findParentNode1.deptAncestor = findChildData.organizeIds;
          findParentNode1.isDefaultDept = e.isDefaultDept;
          findParentNode1.userId = e.id;
          findParentNode1.account = e.account;
          findParentNode1.realName = e.realName;
          findParentNode1.roleId = [];
          if (typeof e.roleId != "undefined") {
            findParentNode1.roleId = e.roleId.split(",");
          }
          deptList.push(findParentNode1);
        }
      });
      this.departmentUserDialog = true;
      this.$nextTick(() => {
        if (deptList.length > 0) {
          this.$refs.departmentUsers.getDataList();
          this.$refs.departmentUsers.deptList = deptList;
        }
      });

      this.roleConfigDialog = false;
    },
    submitRole() {
      var submit = this.$refs.departmentUsers.submit();
      console.log("进行submitsubmit--", submit);
      //进行组装数据
      //       let data = {
      //         "userIds": this.roleTreeObj.toString(),
      //         "roleIds": submit
      //       }
      // //调用多用户批量设置接口
      theUserModifiesTheRole(submit).then(
        () => {
          this.departmentUserDialog = false;
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess"),
          });
          done();
        },
        (error) => {
          window.console.log(error);
          loading();
        }
      );
      // const roleList = this.$refs.treeRole.getCheckedKeys().join(",");
      // grant(this.ids, roleList).then(() => {
      //   this.roleBox = false;
      //   this.$message({
      //     type: "success",
      //     message: this.$t("cip.plat.sys.user.msg.ioaWarning")
      //   });
      //   this.onLoad(this.page);
      // });
    },
    rowSave(row, done, loading) {
      row.deptId = row.deptId.join(",");
      row.roleId = row.roleId.join(",");
      row.postId = row.postId.join(",");
      add(row).then(
        () => {
          this.initFlag = false;
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess"),
          });
          done();
        },
        (error) => {
          window.console.log(error);
          loading();
        }
      );
    },
    rowUpdate(row, index, done, loading) {
      row.deptId = row.deptId.join(",");
      row.roleId = row.roleId.join(",");
      row.postId = row.postId.join(",");
      update(row).then(
        () => {
          this.initFlag = false;
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess"),
          });
          done();
        },
        (error) => {
          window.console.log(error);
          loading();
        }
      );
    },
    rowDel(row) {
      this.$confirm(this.$t("cip.cmn.msg.warning.delWarning"), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      })
        .then(() => {
          return remove(row.id);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess"),
          });
        });
    },
    searchReset(data) {
      this.query = {};
      this.treeDeptId = "";
      this.onLoad(this.page);
    },
    searchChange(params, done) {
      if (params.account == "") {
        delete params.account;
      }
      if (params.realName == "") {
        delete params.realName;
      }

      if (params.userTypeName == "") {
        delete params.userTypeName;
      }
      delete params.$userTypeName;
      console.log("1427",params)
      this.query = params;
      this.page.currentPage = 1;
      this.onLoad(this.page, this.query);
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    selectionClear() {
      this.selectionList = [];
    },
    handleDelete() {},
    handleReset() {
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t("cip.cmn.msg.warning.selectWarning"));
        return;
      }
      this.$confirm(this.$t("cip.plat.sys.user.btn.restPassword"), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      })
        .then(() => {
          return resetPassword(this.ids);
        })
        .then(() => {
          this.$message({
            type: "success",
            message: this.$t("cip.plat.sys.user.msg.iPSWarning"),
          });
        });
    },
    handleGrant() {
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t("cip.cmn.msg.warning.selectWarning"));
        return;
      }
      this.roleConfigDialog = true;
      // this.roleTreeObj = [];
      // let roleIdList = []
      // this.selectionList.forEach(e => {
      //   roleIdList.push(e.id)
      // })
      // this.roleTreeObj = roleIdList
      // getRoleTree().then(res => {
      //   this.roleGrantList = res.data.data;
      //   this.roleBox = true;
      // });
    },
    handlePlatform() {
      this.platformBox = true;
    },
    handleLock() {
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t("cip.cmn.msg.warning.selectWarning"));
        return;
      }
      this.$confirm(this.$t("cip.plat.sys.user.field.restAccount"), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      })
        .then(() => {
          return unlock(this.ids);
        })
        .then(() => {
          this.$message({
            type: "success",
            message: this.$t("cip.plat.sys.user.msg.dlocWarning"),
          });
        });
    },
    handleEnable() {
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t("cip.cmn.msg.warning.selectWarning"));
        return;
      }
      this.$confirm(this.$t("cip.plat.sys.user.msg.enable"), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      })
        .then(() => {
          return enable(this.ids);
        })
        .then(() => {
          this.$message({
            type: "success",
            message: this.$t("cip.plat.sys.user.msg.enableSuccess"),
          });
        });
    },
    handleDisable() {
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t("cip.cmn.msg.warning.selectWarning"));
        return;
      }
      this.$confirm(this.$t("cip.plat.sys.user.msg.disable"), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      })
        .then(() => {
          return disable(this.ids);
        })
        .then(() => {
          this.$message({
            type: "success",
            message: this.$t("cip.plat.sys.user.msg.disableSuccess"),
          });
        });
    },
    handleImport() {
      this.excelBox = true;
    },
    uploadAfter(res, done, loading, column) {
      window.console.log(column);
      this.excelBox = false;
      this.refreshChange();
      done();
    },
    handleExport() {
      this.$confirm(
        this.$t("cip.plat.sys.user.field.exportData"),
        this.$t("cip.plat.sys.user.field.notice"),
        {
          confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
          cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
          type: "warning",
        }
      ).then(() => {
        NProgress.start();
        exportBlob(
          `/api/sinoma-user/export-user?${
            this.website.tokenHeader
          }=${getToken()}&account=${this.searchForm.account || ""}&realName=${
            this.searchForm.realName || ""
          }`
        ).then((res) => {
          downloadXls(
            res.data,
            this.$t("cip.plat.sys.user.field.userData") + `${dateNow()}.xlsx`
          );
          NProgress.done();
        });
      });
    },
    handleTemplate() {
      exportBlob(
        `/api/sinoma-user/export-template?${
          this.website.tokenHeader
        }=${getToken()}`
      ).then((res) => {
        downloadXls(
          res.data,
          this.$t("cip.plat.sys.user.field.userDataTemplate") + ".xlsx"
        );
      });
    },
    beforeOpen(done, type) {
      if (["edit", "view"].includes(type)) {
        getUser(this.form.id).then((res) => {
          this.form = res.data.data;
          if (this.form.hasOwnProperty("deptId")) {
            this.form.deptId = this.form.deptId.split(",");
          }
          if (this.form.hasOwnProperty("roleId")) {
            this.form.roleId = this.form.roleId.split(",");
          }
          if (this.form.hasOwnProperty("postId")) {
            this.form.postId = this.form.postId.split(",");
          }
        });
      }
      this.initFlag = true;
      done();
    },
    currentChange(currentPage) {
      this.page.currentPage = currentPage;
    },
    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
    },
    refreshChange() {
      this.onLoad(this.page, this.query);
    },
    onLoad(page, params = {}) {
      this.query = params
      this.page = page;
      this.tableLoading = true;
      if (this.query.personnelType) {
        this.query.personnelTypes = this.query.personnelType.join(',')
      }
      getList({
        ...params,...this.query,
        current: page.currentPage,
        size: page.pageSize,
        deptId: this.treeDeptId,
      }
      ).then((res) => {
        const data = res.data.data;
        this.$refs.gridLayout.page.total = data.total;
        this.tableData = data.records;
        this.tableLoading = false;
        // this.selectionClear();
      });
    },
    platformRowUpdate(row, index, done, loading) {
      updatePlatform(row.id, row.userType, row.userExt).then(
        () => {
          this.platformOnLoad(this.platformPage);
          this.$message({
            type: "success",
            message: this.$t("cip.plat.sys.user.msg.CfgWarning"),
          });
          done();
        },
        (error) => {
          window.console.log(error);
          loading();
        }
      );
    },
    platformBeforeOpen(done, type) {
      if (["edit", "view"].includes(type)) {
        getUserPlatform(this.platformForm.id).then((res) => {
          this.platformForm = res.data.data;
        });
      }
      done();
    },
    platformSearchReset() {
      this.platformQuery = {};
      this.platformOnLoad(this.platformPage);
    },
    platformSearchChange(params, done) {
      this.platformQuery = params;
      this.platformPage.currentPage = 1;
      this.platformOnLoad(this.platformPage, params);
    },
    platformSelectionChange(list) {
      this.platformSelectionList = list;
    },
    platformSelectionClear() {
      this.platformSelectionList = [];
      this.$refs.platformCrud.toggleSelection();
    },
    platformCurrentChange(currentPage) {
      this.platformPage.currentPage = currentPage;
    },
    platformSizeChange(pageSize) {
      this.platformPage.pageSize = pageSize;
    },
    platformRefreshChange() {
      this.platformOnLoad(this.platformPage, this.platformQuery);
    },
    platformOnLoad(page, params = {}) {
      this.platformLoading = true;
      this.page = page;
      getList(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.query),
        this.treeDeptId
      ).then((res) => {
        const data = res.data.data;
        this.$refs.gridLayout.page.total = data.total;
        this.platformData = data.records;
        this.platformLoading = false;
        this.selectionClear();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.leftTree {
  width: 280px;
  border-right: 1px solid #ccc;
  flex-direction: 0;
}

::v-deep .leftTree .common_tree_handle .el-tree {
  height: calc(100vh - 237px) !important;
}

.el-scrollbar {
  height: 100%;
}

.box .el-scrollbar__wrap {
  overflow: scroll;
}

.roleDialog ::v-deep .el-dialog__body {
  padding: 10px 0 !important;
}
</style>
